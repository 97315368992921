/** @format */

import React from "react";

interface TestimonialProps {
   image: string;
   text: string;
   name: string;
   title: string;
}

const TestimonialCard = ({ image, text, name, title }: TestimonialProps) => {
   return (
      <div className="max-w-[60rem] lg:w-[40%] md:w-[50%] w-[90%] mt-[10rem] mx-auto md:p-10 p-5 max-md:py-8 md:border-4 border-2 border-[#1c1c1c]  bg-black md:rounded-2xl rounded-3xl roun">
         <img
            src={image}
            alt={name}
            className="rounded-full w-[13rem] object-cover h-[13rem] max-md:w-[10rem] max-md:h-[10rem] mx-auto md:mt-[-10rem] mt-[-9rem]"
         />
         <p className="text-white text-center lg:text-[1.8rem]  text-[1.2rem] mt-[1.5rem] md:mt-[3rem]">{text}</p>
         <p className="text-blue-500 text-center lg:text-[2.5rem] text-[2rem] font-semibold mt-[1rem]">{name}</p>
         <p className="text-[#929292] text-center text-[1.6rem] lg:mt-[1rem] mt-1">{title}</p>
      </div>
   );
};

export default TestimonialCard;
