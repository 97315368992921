import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="65"
      fill="none"
      viewBox="0 0 64 65"
    >
      <path
        fill="#929292"
        d="M57.99 32.515c0-14.359-11.642-26-26-26-14.36 0-26 11.641-26 26s11.64 26 26 26c14.358 0 26-11.641 26-26zm-32.172 0l10.585 10.586a2 2 0 01-2.828 2.828l-12-12a2 2 0 010-2.828l12-12a2 2 0 012.828 2.828L25.818 32.515z"
      ></path>
    </svg>
  );
}

export default Icon;
