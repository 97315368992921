/** @format */

import ProfilepotHoverImg from "assets/images/ProfilepotHover.png";
import TBMHoverImg from "assets/images/TBMHover.png";
import PixelgenHoverImg from "assets/images/PixelgenHover.png";
import DocpodHoverImg from "assets/images/DocpodHover.png";
import YABAHoverImg from "assets/images/YABAHover.png";
import TripExpensorImg from "assets/images/TripExpensor.png"; //
import ImagineInteriorHoverImg from "assets/images/ImagineinteriorHover.png";
import BuildIcon from "assets/icons/BuildIcon";
import PartnerIcon from "assets/icons/PartnerIcon";
import ProfessionalImg from "assets/images/Professionals.png";
import ProductImg from "assets/images/Product.png";
import SpeedImg from "assets/images/Speed.png";
import prashanth from "assets/images/testimonials/prashanth.jpeg";
import dinesh from "assets/images/testimonials/dinesh.jpeg";
import maruti from "assets/images/testimonials/maruthi.jpeg";
import ruben from "assets/images/testimonials/ruben.jpeg";
import vijay from "assets/images/testimonials/vijay.jpeg";
import FounderImg from "assets/images/FounderImg.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const ProductsData = [
   {
      title: "Profile pot",
      description: "A better and cleaner way to express your professional self.",
      buttonLabel: "Try it now",
      imageUrl: ProfilepotHoverImg,
      productLink: "https://profilepot.me/",
   },
   {
      title: "The Beautiful Menu",
      description: "Give your customers a fantastic dining experience by letting your menu do the talking.",
      buttonLabel: "Try it now",
      imageUrl: TBMHoverImg,
      productLink: "https://www.thebeautifulmenu.in/",
   },
   {
      title: "PixelGen",
      description: "The world's only no-prompt image generation platform. Create stunning visuals with AI.",
      buttonLabel: "Try it now",
      imageUrl: PixelgenHoverImg,
      productLink: "https://www.pixelgen.art",
   },
   {
      title: "Docpod",
      description: "A privacy-first document manager",
      buttonLabel: "Try it now",
      imageUrl: DocpodHoverImg,
      productLink: "https://play.google.com/store/apps/details?id=com.docpod",
   },
   {
      title: "Imagine Interior",
      description: "Imagine your interior with the power of AI.",
      buttonLabel: "Try it now",
      imageUrl: ImagineInteriorHoverImg,
      productLink: "https://interior.pixelgen.art/",
   },
   {
      title: "YABA",
      description: "A powerful financial app that helps you plan better and save more.",
      buttonLabel: "Try it now",
      imageUrl: YABAHoverImg,
      productLink: "",
   },
   {
      title: "Trip Expensor",
      description: "Take control of your trip's expenses.",
      buttonLabel: "Try it now",
      imageUrl: TripExpensorImg,
      productLink: "https://play.google.com/store/apps/details?id=com.react_native_expensor_app",
   },
];

export const pricingData = [
   {
      highlight: "$5000",
      title: "Build your MVP",
      descriptionItems: ["One-time payment", "2-Week Delivery Time", "End-to-end (Pricing, authentication)", "Go-to-market advisory"],
      finalNote: "MVP will be agreed upon in the initial call",
      icon: <BuildIcon />,
   },
   {
      highlight: "Let's Talk",
      title: "Partnership",
      descriptionItems: ["For more complex products", "Ongoing support and engagement", "Continuous engagement"],
      finalNote: "",
      icon: <PartnerIcon />,
   },
];

export const faqsData = [
   {
      question: "How do we decide what the MVP is?",
      answer: "An MVP is one essential feature of your idea or product that provides value to the customer. We will agree upon the MVP during our first call.",
   },
   {
      question: "Can I provide feedback and iterate on the product?",
      answer: "Yes, absolutely! We are open to feedback. However, we're also very cautious to ensure we remain on track, so we'll discuss any major design and development changes with you.",
   },
   {
      question: "When should you choose us?",
      answer:
         "Choose us if you're looking for a partner to develop, build, market, and sell your idea. We're not just a dev agency that executes tasks; we collaborate with you to bring your vision to life and ensure its success in the market.",
   },
   {
      question: "Why should you choose us?",
      answer:
         "Anand brings technical expertise from building over 14+ products, while Sanjeev adds years of experience in go-to-market (GTM) and marketing strategies. Together, they ensure you get not just an MVP, but a comprehensive GTM strategy. Their passion for product development and design means you'll be working with a team that truly understands and empathizes with the challenges of your product journey.",
   },
];

export const testimonialsData = [
   {
      image: prashanth,
      text: '"Feeling so much gratitude and satisfaction to work this team to build my dream product. They exceeded my expectations in terms of client service, flexibility and achieving the envisioned output."',
      name: "Prashanth S",
      title: "Founder & CEO, Carbon Karma (San Fransico, California)",
   },
   {
      image: dinesh,
      text: "We are proud to collaborate with Greyfeathers, a premier development partner that helps us realize our vision. Under the leadership of CEO Anandraj, Greyfeathers excels in innovation, user-centric design, and exceptional performance. By leveraging cutting-edge technology, they consistently deliver high-quality solutions that elevate IDcolab. Greyfeathers expertise has significantly enhanced our capabilities, enabling us to achieve our ambitious goals. Their team brings extensive experience in software development and design, driving IDcolab to make a difference.",
      name: "Dinesh",
      title: "Founder & CEO, IDColab (Boston, United States)",
   },
   {
      image: maruti,
      text: "Partnering with Greyfeathers was the catalyst for launching Typeless. They expertly transformed my fragmented ideas into a cohesive, beautifully crafted concept. The Greyfeathers team not only excels in their craft but also demonstrates genuine empathy in their approach to thinking, building, and interacting. As we roll out Unifie, I'm confident this collaboration will be the first of many successful ventures together.",
      name: "Maruthi Prithivirajan",
      title: "Founder & CEO, Typeless (Singapore)",
   },
   {
      image: vijay,
      text: "We had the pleasure of working with GreyFeathers on several projects, and their expertise in designing tools and websites is exceptional. They deliver innovative, user-friendly solutions that align perfectly with our needs. Their attention to detail, creativity, and professionalism make the collaboration process smooth and enjoyable. We highly recommend GreyFeathers for their top-notch design services and commitment to excellence",
      name: "Vijayakumar Jayaraman",
      title: "Founder & CEO, Provassure",
   },
];

export const whyGfStudioData = [
   {
      image: ProfessionalImg,
      title: "MicroSaaS Expertise",
      description: "We're experienced MicroSaaS builders (built 10+ products)",
   },
   {
      image: ProductImg,
      title: "Speed",
      description: "We can turn ideas into MVPs in less than 3 weeks",
   },
   {
      image: SpeedImg,
      title: "Product Mindset",
      description: "We're product builders first, then engineers.",
   },
];

export const founders = [
   {
      name: "Anand",
      title: "Founder & CEO, ",
      hyperlink: "Greyfeathers",
      image: FounderImg,
      link: "https://www.greyfeathers.io/",
   },
   {
      name: "Sanjeev NC",
      title: "Co-founder of ",
      hyperlink: "Supermeme.ai",
      image: FounderImg,
      link: "https://www.supermeme.ai/",
   },
];
const innerWidth = window.innerWidth;
export const socialMediaData = [
   {
      id: 1,
      icon: <LinkedInIcon sx={{ fontSize: innerWidth > 768 ? "2.5rem" : "1.8rem" }} />,
      url: "https://www.linkedin.com/company/82795314/admin/feed/posts/",
   },
];

export const howitWorksData = [
   {
      step: "1",
      title: "Explore",
      description: "We get on a call with you to talk through your idea",
   },
   {
      step: "2",
      title: "Scope",
      description: "We work with you to agree on the scope of work",
   },
   {
      step: "3",
      title: "Design",
      description: "We design an interactive prototype in Figma",
   },
   {
      step: "4",
      title: "Build",
      description: "We build the product and hand over the MVP",
   },
   {
      step: "5",
      title: "Strategize",
      description: "We'll leave you with a personalized high-level go-to-market strategy for your product",
   },
];
export const legalAgreements = [
   {
      id: 1,
      title: "Privacy Policy",
      url: "https://cumbersome-petalite-733.notion.site/Privacy-policy-45d0ae3413774928b89bf33a019fc2d9",
   },
   {
      id: 2,
      title: "Terms & Conditions",
      url: "https://cumbersome-petalite-733.notion.site/Terms-and-conditions-38a4c418137f4354862210f3721e1723?pvs=4",
   },
];
