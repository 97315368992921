import React from "react";

function Icon() {
  const innerWidth = window.innerWidth;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={innerWidth > 768 ? "74" : "64"}
      height={innerWidth > 768 ? "74" : "64"}
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect width="64" height="64" fill="#fff" rx="32"></rect>
      <path
        fill="#1E90FF"
        d="M51.734 28.86l-3.987-7.977a2.5 2.5 0 00-3.355-1.118l-3.886 1.943-8.186-2.166a1.27 1.27 0 00-.64 0l-8.187 2.166-3.885-1.943a2.501 2.501 0 00-3.355 1.118l-3.988 7.976a2.501 2.501 0 001.117 3.355l4.22 2.11 8.67 6.193c.127.09.271.157.423.195l10 2.5a1.25 1.25 0 001.188-.328l6.25-6.25 2.356-2.356 4.128-2.064a2.5 2.5 0 001.117-3.353zm-8.577 5.215l-5.376-4.307a1.25 1.25 0 00-1.669.096c-2.783 2.803-5.884 2.448-7.862 1.198l6.756-6.562h4.97L44.228 33l-1.07 1.074zm-6.542 6.54l-9.08-2.27-7.688-5.49 4.375-8.75L32 22.044l1.53.404-7.03 6.825-.013.014a2.5 2.5 0 00.425 3.877c3.213 2.051 7.09 1.719 10.142-.781l4.32 3.47-4.759 4.762zm-4.018 5.438a1.25 1.25 0 01-1.516.91l-6.517-1.63a1.235 1.235 0 01-.424-.196l-4.117-2.94a1.25 1.25 0 011.453-2.032l3.924 2.803 6.287 1.57a1.25 1.25 0 01.91 1.515z"
      ></path>
    </svg>
  );
}

export default Icon;
