import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <g stroke="#929292" strokeMiterlimit="10" clipPath="url(#clip0_84_2052)">
        <path d="M1.375 3.972v6.043c0 .364.355.594.646.42l5.165-3.09c.252-.151.252-.549 0-.7L2.02 3.555c-.29-.177-.646.054-.646.417zM7.626 3.972v6.043c0 .364.355.594.646.42l5.165-3.09c.252-.151.252-.549 0-.7l-5.165-3.09c-.29-.177-.646.054-.646.417z"></path>
      </g>
      <defs>
        <clipPath id="clip0_84_2052">
          <path fill="#fff" d="M0 0H14V14H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
