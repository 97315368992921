import React, { useEffect } from "react";
import HeroSection from "./components/HeroSection";
import InfoSection from "components/InfoSection";
import WhyGfStudio from "components/WhyGfStudio";
import HowItWorks from "components/HowItWorks";
import OurProducts from "components/OurProducts";
import Founders from "components/Founders";
import Pricing from "components/Pricing";
import Faqs from "components/Faqs";
import Footer from "components/Footer";
import Testimonial from "components/Testimonial";
import HeroSectionBackground from "assets/images/HeroSectionBackground.png";
import "aos/dist/aos.css";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init({
      disable: false,
      duration: 1000, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);
  return (
    <div className="overflow-hidden">
      <HeroSection />
      <InfoSection />
      <WhyGfStudio />
      <HowItWorks />
      <OurProducts />
      <Founders />
      <Testimonial />
      <Pricing />
      <Faqs />
      <Footer />
    </div>
  );
}

export default App;
