import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="32"
      fill="none"
      viewBox="0 0 3 32"
    >
      <path
        stroke="#929292"
        strokeDasharray="4 4"
        strokeWidth="2"
        d="M1.5 32L1.5 0"
      ></path>
    </svg>
  );
}

export default Icon;
