import React, { useState } from "react";
import ProductCard from "./ProductCard";
import SectionHeader from "components/SectionHeader";
import { ProductsData } from "data/ProductsData";
import ChevronBack from "assets/icons/ChevronBack";
import ChevronForward from "assets/icons/ChevronForward";
import Slider from "react-slick";

const OurProducts = () => {
  const [startIndex, setStartIndex] = useState(0);
  const innerWidth = window.innerWidth;
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    arrows: false,
  };

  const handlePrevious = () => {
    setStartIndex((prevState) => {
      if (prevState - 2 < 0) return ProductsData.length - 2;
      return prevState - 2;
    });
  };

  const handleNext = () => {
    setStartIndex((prevState) => (prevState + 2) % ProductsData.length);
  };

  const visibleCards = [
    ProductsData[startIndex],
    ProductsData[(startIndex + 1) % ProductsData.length],
  ];

  return (
    <div className="lg:p-[5rem] md:p-[3rem] p-[2rem] max-md:pb-[5rem] bg-black">
      <SectionHeader title="Our Products" />
      {innerWidth > 768 ? (
        <div className="flex xl:gap-[7rem] md:gap-[2rem] lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem] justify-center items-center">
          <button
            className="text-white text-[4rem] active:scale-95 transition-all duration-200"
            onClick={handlePrevious}
          >
            <ChevronBack />
          </button>
          {visibleCards.map((card, index) => (
            <ProductCard key={index} {...card} />
          ))}
          <button
            className=" text-white text-[4rem] active:scale-95 transition-all duration-200"
            onClick={handleNext}
          >
            <ChevronForward />
          </button>
        </div>
      ) : (
        // <div className="flex flex-col justify-center items-center gap-[2rem] py-[2rem]">
        <Slider {...settings}>
          {ProductsData.map((card, index) => (
            <ProductCard key={index} {...card} />
          ))}
        </Slider>
        // </div>
      )}
    </div>
  );
};

export default OurProducts;
