import React, { useState } from "react";

interface ProfileCardProps {
  name: string;
  title: string;
  image: string;
  hyperlink: string;
  link: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  title,
  image,
  hyperlink,
  link,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const innerWidth = window.innerWidth;
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="md:bg-black hover:md:bg-[#1C1C1C] bg-[#1c1c1c] max-md:w-[70%] max-sm:w-full md:text-[#929292] hover:md:text-white text-white border-[3px] border-[#1c1c1c] transition-all duration-500 md:rounded-2xl rounded-[25px] md:p-10 p-5 2xl:w-[50rem] xl:w-[40rem] text-center"
    >
      <div className="flex flex-col items-center gap-3">
        <img
          src={image}
          alt="Profile Avatar"
          className={`md:w-48 w-28 md:h-48 h-28 rounded-full ${
            !isHovered && innerWidth > 768 ? "filter grayscale" : ""
          } transition-all duration-500`}
        />
        <h1 className="2xl:text-[2.8rem] md:text-[2.4rem] text-[2.2rem] font-semibold">
          {name}
        </h1>
        <div>
          <p className="2xl:text-[1.7rem] md:text-[1.5rem] text-[1.3rem] font-medium inline">
            {title}
          </p>
          <a
            href={link}
            className={`2xl:text-[1.7rem] md:text-[1.5rem] transition-all text-[1.3rem] font-medium hover:underline ${
              isHovered && "text-blue-500"
            }`}
          >
            {hyperlink}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
