/** @format */

import React from "react";

interface PricingCardProps {
   highlight: string;
   title: string;
   descriptionItems: string[];
   finalNote: string;
   icon: any;
}

const PricingCard: React.FC<PricingCardProps> = ({ icon, title, highlight, descriptionItems, finalNote }) => {
   return (
      <div className="bg-[#000] border-[#1c1c1c] shadow-2xl border-2 text-white flex flex-col gap-3 items-center justify-start rounded-3xl md:p-10 p-5 2xl:w-[45rem] md:w-[40rem] sm:w-[70%] max-md:mx-auto w-full  shadow-lg">
         <div className="md:mt-[-6.5rem] mt-[-5.5rem]">{icon}</div>
         <h2 className="2xl:text-[2.3rem] md:text-[2rem] text-[1.8rem] font-semibold mt-2">{title}</h2>
         {/* <h1 className="text-blue-500 2xl:text-[4.5rem] text-[4rem] font-semibold">
        {highlight}
      </h1> */}
         <ul className="text-center space-y-2">
            {descriptionItems.map((item, index) => (
               <li
                  key={index}
                  className="2xl:text-[1.5rem] text-[1.7rem]">
                  <span className="mr-1.5 text-[2rem]">•</span> {item}
               </li>
            ))}
         </ul>
         {/* <p className=" 2xl:text-[1.5rem] text-[1.3rem] text-center font-medium mt-3">
        {finalNote}
      </p> */}
      </div>
   );
};

export default PricingCard;
