import SectionHeader from "components/SectionHeader";
import React from "react";
import StepCard from "components/HowItWorks/StepCard";
import { title } from "process";
import { howitWorksData } from "data/ProductsData";
const HowItWorks = () => {
  return (
    <div className="lg:p-[5rem] md:p-[3rem] p-[2rem] bg-black">
      <SectionHeader title="How it Works" />
      <div className="flex flex-col md:gap-[7rem] lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem]">
        {howitWorksData.map((data, index) => (
          <StepCard
            key={index}
            index={index}
            stepNumber={data.step}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
