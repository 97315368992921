import React from "react";

interface ButtonProps {
  onClick?: () => void;
  butttonText: string;
  className?: string;
}

const Button = ({ onClick, butttonText, className }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${className} bg-[#000] max-md:bg-white max-md:text-black max-md:active:scale-95 md:border-2 border rounded-[2rem] text-white active:scale-[0.97] font-semibold text-[1.9rem] max-md:text-[1.4rem] tracking-wide px-[1.8rem] pt-[0.8rem] pb-[0.7rem]  active:max-md:text-black hover:md:bg-white  hover:md:text-black`}
    >
      {butttonText}
    </button>
  );
};

export default Button;
