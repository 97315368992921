import React, { useState } from "react";

interface WhyGfStudioCardProps {
  image: string;
  title: string;
  description: string;
}

const WhyGfStudioCard: React.FC<WhyGfStudioCardProps> = ({
  image,
  title,
  description,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const innerWidth = window.innerWidth;
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="bg-[#0d0d0d] hover:md:bg-[#1c1c1c] max-md:bg-[#1c1c1c] transition-all duration-500 2xl:p-[2.5rem] lg:p-[1.5rem] p-[1rem]  md:rounded-[1.5rem] rounded-[2rem] md:border-4 md:border-[#1c1c1c] md:max-w-[45rem] sm:w-[70%] w-full flex flex-col lg:gap-[2rem] gap-[1rem] items-center text-center mx-auto "
    >
      <img
        src={image}
        alt={title}
        className={` w-24 h-24 ${
          !isHovered && innerWidth > 768 ? "md:filter grayscale" : ""
        } transition-all duration-500`}
      />
      <h2
        className={`${
          isHovered ? "md:text-white" : "md:text-[#929292]"
        } font-semibold 2xl:text-[3rem] lg:text-[2.4rem] max-md:text-white md:text-[1.9rem] text-[1.7rem] transition-all duration-500`}
      >
        {title}
      </h2>
      <p
        className={`${
          isHovered ? "md:text-white" : "md:text-[#929292]"
        } font-medium 2xl:text-[2rem] lg:text-[1.7rem] md:text-[1.5rem] text-[1.3rem] max-md:text-white transition-all duration-500`}
      >
        {description}
      </p>
    </div>
  );
};

export default WhyGfStudioCard;
