/** @format */

import React from "react";
import EllipseImg from "assets/images/EllipseImg.png";
import TopFrameOne from "assets/images/TopFrameOne.png";
import TopFrameTwo from "assets/images/TopFrameTwo.png";
import LeftFrame from "assets/images/LeftFrame.png";
import RightFrame from "assets/images/RightFrame.png";
import MobileLeftFrame from "assets/images/MobileLeftFrame.png";
import ButtonArrow from "assets/icons/ButtonArrowIcon";
import MobileRightFrame from "assets/images/MobileRightFrame.png";

const Hero = () => {
   const innerWidth = window.innerWidth;
   const [isHovered, setIsHovered] = React.useState(false);

   const handleMouseEnter = () => {
      setIsHovered(true);
   };

   const handleMouseLeave = () => {
      setIsHovered(false);
   };

   return (
      <div className="text-white bg-black max-md:bg-black md:h-[110vh] h-[90vh] flex flex-col justify-center items-center relative">
         <div className="flex flex-col justify-center items-center gap-[3rem] md:mt-[-5%] ">
            <h1 className="opacityAnimation text-white 2xl:text-[4.5rem] lg:text-[3.5rem] md:text-[3rem] text-[2rem]  font-semibold max-md:mb-[3rem]">
               Grey<span className="text-[#929292]">feathers</span> Studios
            </h1>
            <h2 className="opacityAnimation 2xl:text-[7rem] lg:text-[6rem] md:text-[4rem] text-[3rem] font-semibold leading-tight text-center">
               Turn your MicroSaaS Ideas into <br />
               <span className="relative">
                  <div className="w-[110%] slideAnimation h-[3px] bg-[#D90429] absolute md:top-[60%] top-[55%] md:left-[-9px] left-[-6px]"></div>
                  code
               </span>{" "}
               <span className="text-blue-500">revenue</span>
            </h2>
            <p className="opacityAnimation font-medium text-[#929292] text-center 2xl:text-[3rem] lg:text-[2.5rem] md:text-[] text-[1.5rem] max-sm:px-2">
               We build MicroSaaS product MVPs in <span className="text-white">3 weeks</span>.
            </p>
         </div>
         <div className="z-20 absolute bottom-0 left-1/2  translate-x-[-50%]">
            <div
               onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
               className="relative ">
               <a
                  href="#infosection"
                  className="bg-[#000] md:border-2 border rounded-[2rem] text-white active:scale-[0.97] font-semibold text-[1.9rem] max-md:text-[1.4rem] tracking-wide px-[1.8rem] pt-[0.8rem] pb-[0.7rem] active:max-md:bg-white active:max-md:text-black hover:md:bg-white hover:md:text-black transition-all duration-500 cursor-pointer"
                  onClick={() => {}}>
                  EXPLORE
               </a>
               {isHovered && innerWidth > 768 && (
                  <span className="absolute bottom-[-7rem] left-[40%] transform -translate-x-1/2 animate-bounce ">
                     <ButtonArrow />
                  </span>
               )}
            </div>
         </div>
         <div>
            <img
               src={EllipseImg}
               alt="Hero Background"
               className="absolute left-0 bottom-0 md:animate-pulse"
            />
         </div>
         <img
            data-aos="fade-up"
            src={TopFrameOne}
            alt="Hero Background"
            className="absolute top-0 md:left-[30%] left-[8%] max-md:h-[25rem]"
         />
         <img
            data-aos="fade-down"
            src={TopFrameTwo}
            alt="Hero Background"
            className="absolute top-0 md:right-[30%] right-[8%] max-md:h-[22rem]"
         />
         <img
            data-aos="fade-right"
            src={LeftFrame}
            alt="Hero Background"
            className="absolute left-0 md:top-[50%] max-md:hidden"
         />
         <img
            data-aos="fade-left"
            src={RightFrame}
            alt="Hero Background"
            className="absolute right-0 md:top-[50%] max-md:hidden"
         />
         <img
            data-aos="fade-right"
            src={MobileLeftFrame}
            alt="Hero Background"
            className="absolute left-0 md:hidden top-[80%] max-md:w-[12rem]"
         />
         <img
            data-aos="fade-left"
            src={MobileRightFrame}
            alt="Hero Background"
            className="absolute right-0 md:hidden top-[80%] max-md:w-[12rem]"
         />
      </div>
   );
};

export default Hero;
