import React, { useState } from "react";
import StepLine from "assets/icons/StepLine";
interface StepCardProps {
  stepNumber: string;
  title: string;
  description: string;
  index?: number;
}

const StepCard: React.FC<StepCardProps> = ({
  stepNumber,
  title,
  description,
  index,
}) => {
  const innerwidth = window.innerWidth;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      {innerwidth > 768 ? (
        <div className="relative">
          <div
            data-aos="fade-up"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="text-[#929292] hover:text-white bg-black cursor-pointer text-[2rem]  flex  place-items-center"
          >
            <div
              className={`${
                isHovered && "bg-white text-[#0181FE] "
              } transition-all duration-500 border-2 border-[#1C1C1C] rounded-full w-[7rem] flex items-center justify-center lg:h-[8rem] h-[6rem]`}
            >
              <span className="font-semibold 2xl:text-[2.3rem] lg:text-[2rem] text-[1.8rem]">
                {stepNumber}
              </span>
            </div>
            <div
              className={` ${
                isHovered && "bg-[#1c1c1c]"
              } border-2  border-[#1C1C1C] rounded-full  transition-all duration-500  flex items-center justify-start lg:h-[8rem] h-[6rem] w-[25rem]`}
            >
              <span className="font-semibold lg:pl-[3rem] pl-[2rem] 2xl:text-[2.3rem] lg:text-[2rem] text-[1.8rem]">
                {title}
              </span>
            </div>
            <div
              className={` ${
                isHovered && "bg-[#1c1c1c]"
              } border-2  border-[#1C1C1C] rounded-full  transition-all duration-500 w-full flex items-center justify-start lg:h-[8rem] h-[6rem]`}
            >
              <span className="font-medium pl-[3rem] 2xl:text-[2rem] lg:text-[1.8rem] text-[1.4rem]">
                {description}
              </span>
            </div>
          </div>
          {index === 4 ? null : (
            <span
              data-aos="fade-up"
              className="absolute left-[2.5rem] lg:left-[3rem] lg:top-[10rem] top-[7.5rem]"
            >
              <StepLine />
            </span>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div
            data-aos="fade-up"
            className="bg-[#1C1C1C] sm:w-[70%] w-full p-[1rem] flex flex-col items-center justify-center rounded-[25px] h-[10rem]"
          >
            <p className="text-white text-[1.7rem] font-medium text-center">
              {stepNumber}. {title}
            </p>
            <p className="text-[#929292] text-[1.3rem] text-center mt-2">
              {description}
            </p>
          </div>
          {index === 4 ? null : (
            <span data-aos="fade-up">
              <StepLine />
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default StepCard;
