import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="49"
      fill="none"
      viewBox="0 0 32 49"
    >
      <g clipPath="url(#clip0_84_1746)">
        <path
          fill="#0D0D0D"
          d="M0 0H32V48H0z"
          transform="translate(0 .5)"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.249"
          d="M9.249 9.12l6.75 6.75 6.75-6.75M9.249 33.12l6.75 6.75 6.75-6.75"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_84_1746">
          <path
            fill="#fff"
            d="M0 0H32V48H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
