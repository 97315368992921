import React from "react";
import RightHeaderDesign from "assets/images/RightHeaderDesign.png";
import LeftHeaderDesign from "assets/images/LeftHeaderDesign.png";
import MobileLeftFrame from "assets/images/MobileLeftFrame.png";
import MobileRightFrame from "assets/images/MobileRightFrame.png";
interface SectionHeaderProps {
  title: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  const innnerWidth = window.innerWidth;
  return (
    <div className=" flex items-center justify-center lg:gap-[4rem] md:gap-[2rem] gap-[1rem] md:py-[4rem] py-[2rem]">
      <div>
        <img
          data-aos="fade-right"
          className="w-full h-full"
          src={innnerWidth > 768 ? RightHeaderDesign : MobileLeftFrame}
          alt="Right Header Design"
        />
      </div>
      <p className="lg:text-[4rem] md:text-[3rem] text-[2rem] font-semibold text-white min-w-fit">
        {title}
      </p>
      <div>
        <img
          data-aos="fade-left"
          className="w-full h-full"
          src={innnerWidth > 768 ? LeftHeaderDesign : MobileRightFrame}
          alt="Left Header Design"
        />
      </div>
    </div>
  );
};

export default SectionHeader;
