/** @format */

import React from "react";

interface InfoCardProps {
   highlightedText: string;
   description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ highlightedText, description }) => {
   const innerWidth = window.innerWidth;
   return (
      <div className="max-w-[75rem] mx-auto bg-[#1c1c1c] text-white md:rounded-3xl rounded-[25px] md:p-[3rem] p-[1rem]">
         {highlightedText !== "Founders" ? (
            <h2 className="text-center 2xl:text-[2.7rem] md:text-[2.3rem] text-[1.8rem] font-semibold md:mb-[2rem] mb-[1rem]">
               <span>
                  <span className="text-blue-500 mr-2">{highlightedText}</span>
                  {innerWidth < 1345 && <br />}
                  with a free tool idea
               </span>
            </h2>
         ) : (
            <h2 className="text-center 2xl:text-[2.7rem] md:text-[2.3rem] text-[1.8rem] font-semibold md:mb-[2rem] mb-[1rem]">
               <span>
                  MicroSaaS
                  <span className="text-blue-500 mx-2">{highlightedText}</span>
                  {innerWidth < 1345 && <br />}
                  with a product idea
               </span>
            </h2>
         )}
         <p className="text-center 2xl:text-[2.2rem] md:text-[1.4rem] text-[1.2rem] font-medium">{description}</p>
      </div>
   );
};
export default InfoCard;
