/** @format */

import SectionHeader from "components/SectionHeader";
import React, { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { faqsData } from "data/ProductsData";
import Accordian from "components/Accordion";

const Faqs = () => {
   const [openState, setOpenState] = useState(new Array(faqsData.length).fill(false));
   const innerWidth = window.innerWidth;

   const handleAccordion = (index: number) => {
      const newState = [...openState];
      newState[index] = !newState[index]; // Toggle the state of the clicked FAQ item
      setOpenState(newState);
   };

   return (
      <div className="bg-black pb-[3rem]">
         <SectionHeader title="FAQs" />
         <div className="lg:px-[5rem] ml-[2.3rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem]">
            {faqsData.map((faq, index) => (
               <div className="w-full sm:items-center flex flex-col">
                  <div
                     key={index}
                     className="flex flex-col sm:justify-center  sm:w-[50%] w-[85%]  sm:items-start md:gap-[2rem] gap-[1rem] mb-[3rem]">
                     <div className="flex  gap-3">
                        <span
                           onClick={() => handleAccordion(index)}
                           className="cursor-pointer">
                           {openState[index] ? (
                              <ArrowDropDownIcon
                                 sx={{
                                    color: "#929292",
                                    fontSize: innerWidth > 640 ? "4rem" : "2rem",
                                 }}
                              />
                           ) : (
                              <ArrowRightIcon
                                 sx={{
                                    color: "#929292",
                                    fontSize: innerWidth > 640 ? "4rem" : "2rem",
                                 }}
                              />
                           )}
                        </span>
                        <p
                           onClick={() => handleAccordion(index)}
                           className="text-white 2xl:text-[3rem] lg:text-[2.5rem] sm:text-[2rem] text-[1.7rem] font-medium cursor-pointer">
                           {faq.question}
                        </p>
                     </div>
                     {openState[index] && (
                        <p className="text-[#929292] opacityAnimation sm:ml-[5rem] max-md:ml-[3rem] 2xl:text-[2rem] lg:text-[1.7rem] text-[1.3rem] sm:text-left transition-all duration-300">
                           {faq.answer}
                        </p>
                     )}
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default Faqs;
