import SectionHeader from "components/SectionHeader";
import React from "react";
import ProfileCard from "./ProfileCard";
import { founders } from "data/ProductsData";
const Founders = () => {
  return (
    <div className="bg-black gap-[2rem] lg:p-[5rem] md:p-[3rem] p-[2rem]">
      <SectionHeader title="Who are we?" />
      <div className="flex max-md:flex-col max-md:gap-[2rem] items-center justify-evenly lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem]">
        {founders.map((founder, index) => (
          <ProfileCard
            key={index}
            image={founder.image}
            name={founder.name}
            title={founder.title}
            hyperlink={founder.hyperlink}
            link={founder.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Founders;
