/** @format */

import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";

interface TalkToUsModalProps {
   onClose: () => void;
}

const TalkToUsModal = ({ onClose }: TalkToUsModalProps) => {
   const [opacityAnimation, setOpacityAnimation] = useState(false);

   const handleModalClose = () => {
      setOpacityAnimation(true);
      setTimeout(() => {
         onClose();
      }, 480);
   };

   useEffect(() => {
      const widgetScriptSrc = "https://tally.so/widgets/embed.js";

      const load = () => {
         // Load Tally embeds
         //@ts-ignore
         if (typeof Tally !== "undefined") {
            //@ts-ignore
            Tally.loadEmbeds();
            return;
         }

         // Fallback if window.Tally is not available
         //@ts-ignore
         document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl) => {
            //@ts-ignore
            iframeEl.src = iframeEl.dataset.tallySrc;
         });
      };

      // If Tally is already loaded, load the embeds
      //@ts-ignore
      if (typeof Tally !== "undefined") {
         load();
         return;
      }

      // If the Tally widget script is not loaded yet, load it
      if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
         const script = document.createElement("script");
         script.src = widgetScriptSrc;
         script.onload = load;
         script.onerror = load;
         document.body.appendChild(script);
         return;
      }
   }, []);

   return (
      <div className={`${opacityAnimation ? "opacityCloseAnimation" : "opacityAnimation"}  backdrop-blur-sm grid place-items-center fixed top-0 left-0 w-full h-[100vh] z-30`}>
         <div className="bg-[#1c1c1c] md:max-w-[85rem] w-[95%] h-fit  md:p-[4rem] p-[1.5rem] rounded-3xl">
            <div className="mb-2 flex justify-between items-center">
               <p className="text-white font-semibold md:text-[2.5rem] text-[2.2rem]">Talk to us</p>
               <span
                  onClick={handleModalClose}
                  className="cursor-pointer active:scale-95">
                  <CancelIcon sx={{ color: "#fff", fontSize: "2.5rem" }} />
               </span>
            </div>
            <p className="text-[#929292] font-semibold md:text-[1.6rem] text-[1.3rem] mb-7">Please share your basic requirements with us, and we will get back to you as soon as possible.</p>

            <iframe
               data-tally-src="https://tally.so/embed/mYrRBd?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
               loading="lazy"
               width="100%"
               height="361"
               title="Greyfeathers studios - Talk to us"></iframe>
         </div>
      </div>
   );
};

export default TalkToUsModal;
