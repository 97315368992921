import SectionHeader from "components/SectionHeader";
import InfoCard from "../InfoSection/InfoCard/InfoCard";
const InfoSection: React.FC = () => {
  return (
    <div
      id="infosection"
      style={{
        background: "rgb(13, 13, 13)", // Fallback color
        backgroundImage:
          "linear-gradient(180deg, rgba(13,13,13,1) 35%, rgba(0,0,0,1) 100%)",
      }}
      className="lg:p-[5rem] md:p-[3rem] p-[2rem] "
    >
      <SectionHeader title="Who do we work with?" />
      <div className="flex max-md:flex-col lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem] md:gap-[4rem] gap-[2rem]">
        <div className="md:w-[50%]">
          <InfoCard
            highlightedText="Founders"
            description="Have an idea and looking for a technical co-founder? Work with us on your MVP and you’ll get a technical co-founder and more!"
          />
        </div>
        <div className="md:w-[50%]">
          <InfoCard
            highlightedText="Marketing Teams"
            description="Free tools naturally attract backlinks and increase organic traffic. We'll help you identify which free tool fits your domain and build it too!"
          />
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
