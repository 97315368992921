/** @format */

import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { legalAgreements, socialMediaData } from "data/ProductsData";

const Footer: React.FC = () => {
   const innerWidth = window.innerWidth;

   const handleSocialMediaLink = (url: string) => {
      window.open(url, "_blank");
   };

   return (
      <footer className="bg-[#1C1C1C] text-white py-20 xl:px-40 lg:px-20 px-10">
         <div className="flex flex-col md:flex-row justify-between md:items-start items-center ">
            <div className="flex flex-col lg:gap-[3rem] md:gap-[2rem] gap-[1rem] md:items-start items-center max-md:mb-[2rem]">
               <h1 className="xl:text-[3rem] lg:text-[2.5rem] text-[2rem] font-bold lg:mt-[-5px]">
                  Grey<span className="text-[#929292]">feathers</span> Studios
               </h1>
               <div className="flex lg:gap-7 gap-4 ml-1">
                  {socialMediaData.map((item, index) => (
                     <span
                        key={item.id}
                        onClick={() => handleSocialMediaLink(item.url)}
                        className="p-3 bg-black rounded-full cursor-pointer">
                        {item.icon}
                     </span>
                  ))}
               </div>
            </div>
            <div className="flex max-md:flex-col justify-between md:gap-[4rem] gap-[1.5rem] 2xl:w-[45%] lg:w-[50%]">
               <div className="flex flex-col max-md:items-center">
                  <h2 className="xl:text-[2.5rem] text-[2rem] font-semibold md:mb-[3rem] mb-[1rem]">Legal Agreements</h2>
                  {legalAgreements.map((data) => (
                     <a
                        key={data.id}
                        href={data.url}
                        target="_blank"
                        className="leading-relaxed hover:underline transition-all text-[1.5rem] mb-[1rem]">
                        {data.title}
                     </a>
                  ))}
               </div>

               <div className="flex flex-col items-start max-md:items-center">
                  <h2 className="xl:text-[2.5rem] text-[2rem] font-semibold md:mb-[3rem] mb-[1rem]">Contact</h2>
                  <div className="flex items-start gap-3 mb-[1rem]">
                     <span className="mt-[4px]">
                        <MailOutlineIcon />
                     </span>
                     <a
                        href="mailto:support@greyfeathers.io"
                        className="leading-relaxed text-[1.5rem] cursor-pointer">
                        support@greyfeathers.io
                     </a>
                  </div>
                  <div className="flex items-start gap-3 mb-[1rem] cursor-pointer">
                     <span className="mt-[3px]">
                        <LocalPhoneOutlinedIcon />
                     </span>
                     <a
                        href="tel:+917418590412"
                        className="leading-relaxed text-[1.5rem]">
                        +91 74185 90412
                     </a>
                  </div>
                  <div className="flex items-start md:gap-3 mb-[1rem]">
                     <span className="md:mt-[3px] mt-[4px]">
                        {" "}
                        <RoomOutlinedIcon />
                     </span>
                     <p className="text-[1.5rem] leading-relaxed max-md:text-center">
                        GREYFEATHERS (OPC) PRIVATE LIMITED
                        <br /> Door No.3/236 A, NILA STREET, <br />
                        KRISHNANAGAR, Thiruppalai, Madurai, <br /> Tamil Nadu, 625014
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
