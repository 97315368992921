import React from "react";
import TestimonialCard from "./TestimonialCard";
import { testimonialsData } from "data/ProductsData";
import SectionHeader from "components/SectionHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SwipeArrow from "assets/icons/SwipeArrow";

const Testimonials = () => {
  const innerWidth = window.innerWidth;
  console.log(testimonialsData, "testimonialsData");

  const settings = {
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: innerWidth > 768 ? true : false,
    arrows: false,
  };
  return (
    <div className="bg-black md:py-[5rem] py-[2rem]">
      <SectionHeader title="What Our Clients Say About Us" />
      {testimonialsData.length > 1 ? (
        <Slider {...settings} className="">
          {testimonialsData.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              image={testimonial.image}
              text={testimonial.text}
              name={testimonial.name}
              title={testimonial.title}
            />
          ))}
        </Slider>
      ) : (
        <div>
          {testimonialsData.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              image={testimonial.image}
              text={testimonial.text}
              name={testimonial.name}
              title={testimonial.title}
            />
          ))}
        </div>
      )}

      <p className="text-[#929292] animate-pulse md:hidden text-[1.3rem] mt-3 text-center flex items-center justify-center gap-2">
        Swipe Left
        <span>
          <SwipeArrow />
        </span>
      </p>
    </div>
  );
};

export default Testimonials;
