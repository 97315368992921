import React, { useState } from "react";
import PricingCard from "./PricingCard";
import SectionHeader from "components/SectionHeader";
import { pricingData } from "data/ProductsData";
import Button from "components/Button/Button";
import TalkToUsModal from "components/TalkToUsModal";

const Pricing = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div className="lg:p-[5rem] md:p-[3rem] p-[2rem] bg-black">
      <SectionHeader title="Pricing" />
      <div className="lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem] flex max-md:flex-col justify-center lg:gap-[10rem] md:gap-[5rem] gap-[2rem] max-md:gap-[7rem] max-md:mt-[6rem]">
        {pricingData.map((card, index) => (
          <PricingCard key={index} {...card} />
        ))}
      </div>
      <div className="grid place-items-center max-md:mt-5">
        <Button
          className="rounded-2xl px-[5rem]"
          butttonText="Talk to us"
          onClick={handleModal}
        />
      </div>
      <div>{openModal && <TalkToUsModal onClose={handleModal} />}</div>
    </div>
  );
};
export default Pricing;
