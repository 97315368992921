import React from "react";

function Icon() {
  const innerWidth = window.innerWidth;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={innerWidth > 768 ? "74" : "64"}
      height="74"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect width="64" height="64" fill="#fff" rx="32"></rect>
      <path
        fill="#1E90FF"
        d="M37.833 40.167l2.333-2.334a.8.8 0 00.25-.583.8.8 0 00-.25-.583L34.5 30.958a5.63 5.63 0 00.25-.916c.055-.306.083-.653.083-1.042 0-1.583-.562-2.937-1.687-4.061-1.124-1.125-2.479-1.688-4.063-1.689-.472 0-.944.063-1.417.189-.472.125-.93.298-1.375.52l3.917 3.916-2.333 2.334-3.917-3.917a7.138 7.138 0 00-.52 1.375 5.593 5.593 0 00-.188 1.416c0 1.584.563 2.938 1.688 4.064 1.126 1.125 2.48 1.688 4.062 1.687.36 0 .701-.028 1.021-.084.32-.055.633-.139.937-.25l5.708 5.667a.8.8 0 00.584.25.8.8 0 00.583-.25zM32 48.667c-2.306 0-4.473-.438-6.5-1.314-2.028-.875-3.792-2.062-5.292-3.561-1.5-1.5-2.687-3.263-3.562-5.292-.874-2.029-1.312-4.195-1.313-6.5-.001-2.304.437-4.47 1.313-6.5.877-2.029 2.064-3.793 3.562-5.291 1.498-1.5 3.262-2.687 5.292-3.562 2.03-.876 4.196-1.313 6.5-1.313 2.303 0 4.47.437 6.5 1.313 2.03.875 3.794 2.063 5.291 3.562 1.498 1.498 2.686 3.262 3.564 5.291.878 2.03 1.315 4.196 1.311 6.5-.003 2.305-.44 4.471-1.313 6.5-.872 2.03-2.06 3.793-3.562 5.292-1.502 1.499-3.266 2.686-5.291 3.563-2.026.877-4.193 1.314-6.5 1.312zm0-3.334c3.722 0 6.875-1.291 9.458-3.874 2.583-2.584 3.875-5.737 3.875-9.459 0-3.722-1.292-6.875-3.875-9.458-2.583-2.584-5.736-3.875-9.458-3.875-3.723 0-6.875 1.291-9.459 3.875-2.583 2.583-3.875 5.736-3.875 9.458 0 3.722 1.292 6.875 3.875 9.459 2.584 2.583 5.736 3.874 9.459 3.874z"
      ></path>
    </svg>
  );
}

export default Icon;
