import React, { useEffect, useState } from "react";
import Button from "components/Button/Button";

type ProductCardProps = {
  title: string;
  description: string;
  buttonLabel: string;
  productLink: string;
  imageUrl: string;
};

const Card: React.FC<ProductCardProps> = ({
  title,
  description,
  buttonLabel,
  productLink,
  imageUrl,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [animateOpacity, setAnimateOpacity] = useState(false);
  const innerWidth = window.innerWidth;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setAnimateOpacity(true);
    const timeout = setTimeout(() => {
      setAnimateOpacity(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [description]);

  const handleProductLink = (url: string) => {
    if (url.length) {
      window.open(url, "_blank");
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="md:bg-black hover:md:bg-[#1C1C1C] bg-[#1C1C1C] 2xl:w-[50rem] md:w-[40rem] md:h-[30rem] max-md:w-[70%] max-sm:w-[95%] max-md:mx-auto max-md:h-[25rem] rounded-3xl transition-all duration-500 2xl:p-10 p-6 border-4 border-[#1c1c1c] text-center text-white  flex flex-col gap-4 justify-center items-center"
    >
      <div className="w-24 h-24 rounded-full  flex items-center justify-center">
        <img
          src={imageUrl}
          alt={title}
          className={`w-full h-full ${
            !isHovered && innerWidth > 768 ? "filter grayscale" : ""
          } transition-all duration-500 ${
            animateOpacity ? "opacityAnimation" : ""
          }`}
        />
      </div>
      <h2
        className={` ${
          animateOpacity ? "opacityAnimation" : ""
        } 2xl:text-[3rem] lg:text-[2.5rem] text-[2rem] font-semibold transition-all duration-500`}
      >
        {title}
      </h2>
      <p
        className={`2xl:text-[1.8rem] lg:text-[1.4rem] text-[1.2rem] ${
          isHovered ? "md:text-white" : "md:text-gray-400"
        } transition-all duration-500 max-md:text-white  ${
          animateOpacity ? "opacityAnimation" : ""
        }`}
      >
        {description}
      </p>
      <Button
        className={`${
          animateOpacity ? "opacityAnimation" : ""
        } 2xl:text-[1.5rem] text-[1.2rem] border-2 rounded-3xl mt-2`}
        butttonText={productLink === "" ? "Coming soon" : "Try it now"}
        onClick={() => handleProductLink(productLink)}
      />
    </div>
  );
};

export default Card;
