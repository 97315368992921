import SectionHeader from "components/SectionHeader";
import React from "react";
import WhyGfStudioCard from "components/WhyGfStudio/WhyGfStudioCard";
import { whyGfStudioData } from "data/ProductsData";

const WhyGfStudio = () => {
  return (
    <div className="lg:p-[5rem] md:p-[3rem] p-[2rem] bg-black">
      <SectionHeader title="Why Greyfeathers Studios?" />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-[2rem] lg:px-[5rem] md:px-[3rem] lg:py-[5rem] md:py-[3rem] py-[1.5rem]">
        {whyGfStudioData.map((data, index) => (
          <WhyGfStudioCard
            key={index}
            image={data.image}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
};

export default WhyGfStudio;
